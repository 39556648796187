/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { PrimaryCarousel, ContentBlock, Icon } from '@powdr/components';
import {
  CarouselControlPlacement,
  CarouselPaginationPlacement, CarouselPaginationType,
} from '@powdr/constants';
import { CustomPaginationOptions } from '@powdr/constants/carousel';
import { useSeason } from '@powdr/hooks';
import { colorProfileByIndex } from '@powdr/utils';

import { StyledContentBlockCarousel, CarouselIcon } from './styles';

export const ContentBlockCarousel = ({
  id,
  iconName,
  blockType,
  customSlideIndicators,
  slidesToShow,
  spaceBetweenSlides,
  colorProfile,
  relationships,
  parentColorProfile,
}) => {
  const { checkSeasonality } = useSeason();
  const { contentBlocks } = relationships;
  const trueColorProfile = colorProfileByIndex(colorProfile);

  const customIndicators = () => (
    (customSlideIndicators && customSlideIndicators !== CustomPaginationOptions.NO_INDICATORS)
      ? contentBlocks.map((cb) => (
        {
          name: cb.iconName,
          height: cb.height,
        }
      )).filter((e) => e.name !== null)
      : null
  );

  const contentBlockMap = useMemo(
    () => contentBlocks.map((contentBlock, index) => {
      const { id: blockId, season, isHideBlock } = contentBlock;

      if (checkSeasonality(season) && !isHideBlock) {
        return (
          <ContentBlock
            contentBlock={contentBlock}
            key={blockId}
            index={index}
            columnOption={1}
            blockType={blockType}
            colorProfile={trueColorProfile}
          />
        );
      }

      return null;
    }).filter((x) => x),
    [contentBlocks, blockType, checkSeasonality, trueColorProfile],
  );

  if (contentBlockMap?.length === 0) return null;

  return (
    <StyledContentBlockCarousel key={id}>
      {iconName && (
        <CarouselIcon colorProfile={parentColorProfile}>
          <Icon name={iconName} height="50" />
        </CarouselIcon>
      )}
      <PrimaryCarousel
        colorProfile={parentColorProfile}
        controlSettings={(contentBlockMap.length > parseInt(slidesToShow, 10)) && {
          iconName: 'ui-arrow-circle-left',
          size: 45,
          placement: CarouselControlPlacement.BOTTOM,
        }}
        paginationSettings={(customIndicators()?.length > 0
          && contentBlockMap.length > parseInt(slidesToShow, 10)) && {
          type: (customSlideIndicators === CustomPaginationOptions.ICON_INDICATORS)
            ? CarouselPaginationType.ICON_BULLETS
            : CarouselPaginationType.TEXT_BULLETS,
          placement: CarouselPaginationPlacement.TOP,
          customBullets: customIndicators(),
        }}
        slidesPerView={(contentBlockMap.length > parseInt(slidesToShow, 10))
          ? parseInt(slidesToShow, 10)
          : contentBlockMap.length}
        spaceBetweenSlides={spaceBetweenSlides || 0}
      >
        {contentBlockMap}
      </PrimaryCarousel>
    </StyledContentBlockCarousel>
  );
};

ContentBlockCarousel.propTypes = {
  id: PropTypes.string,
  iconName: PropTypes.string,
  blockType: PropTypes.string,
  customSlideIndicators: PropTypes.number,
  slidesToShow: PropTypes.string,
  spaceBetweenSlides: PropTypes.number,
  colorProfile: PropTypes.number,
  parentColorProfile: PropTypes.number,
  relationships: PropTypes.shape(),
};

ContentBlockCarousel.defaultProps = {
  id: '',
  iconName: '',
  blockType: '',
  customSlideIndicators: 0,
  slidesToShow: null,
  spaceBetweenSlides: 0,
  colorProfile: null,
  relationships: null,
  parentColorProfile: null,
};
