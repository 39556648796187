import { Environments } from '@powdr/constants';

export const getDrupalDomain = (property) => `https://cms.${process.env.GATSBY_ACTIVE_ENV === Environments.DEVELOPMENT ? `dev.${property}` : property}${process.env.GATSBY_TLD_OVERRIDE || '.com'}/`;

export const getDrupalViewApiDomain = () => `${getDrupalDomain(process.env.GATSBY_PROPERTY_NAME)}api/v2?display=rest_export_1`;

export const getNodeApiDomain = () => {
  const apiPath = 'api/v1';

  if (process.env.GATSBY_NODE_API === Environments.LOCAL) {
    // When running powdr-node locally you need to spoof your local ie. {property}.dev.local:8000
    return `http://api.${process.env.GATSBY_PROPERTY_NAME}.dev.local:8080/${apiPath}`;
  }

  return `https://api.${(process.env.GATSBY_NODE_API === Environments.DEVELOPMENT)
    ? `dev.${process.env.GATSBY_PROPERTY_NAME}`
    : process.env.GATSBY_PROPERTY_NAME}${process.env.GATSBY_TLD_OVERRIDE || '.com'}/${apiPath}`;
};
